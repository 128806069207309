export const TYPE_INVENTORY_PRE_BUY = 'PRE_BUY';
export const TYPE_INVENTORY_ALLOTMENT = 'ALLOTMENT';

export const TYPE_PRODUCT_VEHICLE_RENTAL_DAILY = 'VEHICLE_RENTAL_DAILY';
export const TYPE_PRODUCT_VEHICLE_POINT_TO_POINT = 'VEHICLE_POINT_TO_POINT';

export const TYPE_TRANSMISSION_AUTOMATIC = 'AUTOMATIC';
export const TYPE_TRANSMISSION_MANUAL = 'MANUAL';
export const TYPE_TRANSMISSION_NOT_APPLICABLE = 'NOT_APPLICABLE';

export const TYPE_DRIVER_WITH_DRIVER = 'WITH_DRIVER';
export const TYPE_DRIVER_WITHOUT_DRIVER = 'WITHOUT_DRIVER';

export const TYPE_BOOKING_STATUS_BOOKED = 'BOOKED';
export const TYPE_BOOKING_STATUS_ISSUED = 'ISSUED';
export const TYPE_BOOKING_STATUS_CANCELLED = 'CANCELLED';
export const TYPE_BOOKING_STATUS_EXPIRED = 'EXPIRED';

export const TYPE_BOOKING_POST_ISSUANCE_STATUS_WAITING_FOR_ACKNOWLEDGE =
  'WAITING_FOR_ACKNOWLEDGE';
export const TYPE_BOOKING_POST_ISSUANCE_STATUS_ACKNOWLEDGED = 'ACKNOWLEDGED';
export const TYPE_BOOKING_POST_ISSUANCE_STATUS_DRIVER_ASSIGNED =
  'DRIVER_ASSIGNED';
export const TYPE_BOOKING_POST_ISSUANCE_STATUS_REJECTED = 'REJECTED';

export const TYPE_SUPPLIER_SERVICE_STANDARD = 'STANDARD';
export const TYPE_SUPPLIER_SERVICE_PREMIUM = 'PREMIUM';

export const TYPE_SUPPLIER_PERSONAL = 'PERSONAL';
export const TYPE_SUPPLIER_COMPANY = 'COMPANY';

export const TYPE_PRODUCT_SERVICE_STANDARD = 'STANDARD';
export const TYPE_PRODUCT_SERVICE_PREMIUM = 'PREMIUM';

export const TYPE_INSTANT_BOOKING_ACTIVE = 'ACTIVE';
export const TYPE_INSTANT_BOOKING_INACTIVE = 'INACTIVE';
export const TYPE_INSTANT_BOOKING_NOT_AVAILABLE = 'NA';
export const TYPE_INSTANT_BOOKING_INSTANT_BOOKING_WITHOUT_QUEUE =
  'INSTANT_BOOKING_WITHOUT_QUEUE';

export const TYPE_ADDON_MAPPING_ROUTE_VEHICLE = 'ROUTE_VEHICLE';
export const TYPE_ADDON_MAPPING_ROUTE_PRODUCT = 'ROUTE_PRODUCT';
export const TYPE_ADDON_MAPPING_VEHICLE = 'VEHICLE';
export const TYPE_ADDON_MAPPING_ROUTE = 'ROUTE';
export const TYPE_ADDON_MAPPING_PRODUCT = 'PRODUCT';

export const TYPE_PAYMENT_PERIOD_DAILY = 'DAILY';
export const TYPE_PAYMENT_PERIOD_WEEKLY = 'WEEKLY';
export const TYPE_PAYMENT_PERIOD_MONTHLY = 'MONTHLY';

export const TYPE_PRODUCT_OFFER_VEHICLE = 'VEHICLE';
export const TYPE_PRODUCT_OFFER_SEAT = 'SEAT';

export const TYPE_ROUTE_POINT = 'POINT';
export const TYPE_ROUTE_ZONAL = 'ZONAL';
export const TYPE_ROUTE_AREA = 'AREA';

export const TYPE_LEAD_TIME_DAY_CONFIGURATION_ALL_DAY = 'ALL_DAY';
export const TYPE_LEAD_TIME_DAY_CONFIGURATION_ONE_DAY = 'ONE_DAY';
export const TYPE_LEAD_TIME_DAY_CONFIGURATION_SPECIFIC_DAYS = 'SPECIFIC_DAYS';

export const TYPE_TURN_AROUND_DAY_CONFIGURATION_ALL_DAY = 'ALL_DAY';
export const TYPE_TURN_AROUND_DAY_CONFIGURATION_ONE_DAY = 'ONE_DAY';

export const TYPE_PREBUY_VALIDITY_BY_BOOKING_DATE = 'VALIDITY_BY_BOOKING_DATE';
export const TYPE_PREBUY_VALIDITY_BY_RENTAL_DATE = 'VALIDITY_BY_RENTAL_DATE';
export const TYPE_PREBUY_VALIDITY_BY_BOOKING_RENTAL_DATE =
  'VALIDITY_BY_BOOKING_RENTAL_DATE';
export const TYPE_PREBUY_VALIDITY_BY_STOCK = 'VALIDITY_BY_STOCK';

export const TYPE_VEHICLE_TYPE_MPV = 'MPV';
export const TYPE_VEHICLE_COMPACT_MPV = 'COMPACT_MPV';
export const TYPE_VEHICLE_TYPE_MINI_MPV = 'MINI_MPV';
export const TYPE_VEHICLE_SUV = 'SUV';
export const TYPE_VEHICLE_CITY_CAR = 'CITY_CAR';
export const TYPE_VEHICLE_MINI_VAN = 'MINI_VAN';
export const TYPE_VEHICLE_MINIBUS = 'MINIBUS';
export const TYPE_VEHICLE_SEDAN = 'SEDAN';
export const TYPE_VEHICLE_LUXURY_SEDAN = 'LUXURY_SEDAN';
export const TYPE_VEHICLE_LIMOUSINE = 'LIMOUSINE';
export const TYPE_VEHICLE_MOTORCYCLE = 'MOTORCYCLE';
export const TYPE_VEHICLE_MEDIUM_BUS = 'MEDIUM_BUS';
export const TYPE_VEHICLE_BIG_BUS = 'BIG_BUS';
export const TYPE_VEHICLE_LUXURY_BUS = 'LUXURY_BUS';
export const TYPE_VEHICLE_BUS = 'BUS';
export const TYPE_VEHICLE_PICKUP_TRUCK = 'PICKUP_TRUCK';

export const TYPE_TRANSPORTATION_TYPE_BUS = 'BUS';
export const TYPE_TRANSPORTATION_TYPE_CAR = 'CAR';
export const TYPE_TRANSPORTATION_TYPE_BIKE = 'BIKE';

export const TYPE_PREBUY_APPROVAL_STATUS_PENDING = 'PENDING';
export const TYPE_PREBUY_APPROVAL_STATUS_NEEDS_APPROVAL = 'NEEDS_APPROVAL';
export const TYPE_PREBUY_APPROVAL_STATUS_NEEDS_MODIFICATION =
  'NEEDS_MODIFICATION';
export const TYPE_PREBUY_APPROVAL_STATUS_APPROVED = 'APPROVED';
export const TYPE_PREBUY_APPROVAL_STATUS_REJECTED = 'REJECTED';

export const TYPE_PREBUY_STATUS_REVERSED = 'REVERSED';
export const TYPE_PREBUY_STATUS_SUSPENDED = 'SUSPENDED';
export const TYPE_PREBUY_STATUS_ALTERED = 'ALTERED';
export const TYPE_PREBUY_STATUS_INVALIDATED = 'INVALIDATED';
export const TYPE_PREBUY_STATUS_INACTIVE = 'INACTIVE';
export const TYPE_PREBUY_STATUS_ACTIVE = 'ACTIVE';
export const TYPE_PREBUY_STATUS_EXPIRED = 'EXPIRED';
export const TYPE_PREBUY_STATUS_EXTENDED = 'EXTENDED';

export const TYPE_PREBUY_SORT_NEAREST_EXPIRY_DATE = 'NEAREST_EXPIRY_DATE';
export const TYPE_PREBUY_SORT_RECENT_CREATED_DATE = 'RECENT_CREATED_DATE';
export const TYPE_PREBUY_SORT_OLDEST_CREATED_DATE = 'OLDEST_CREATED_DATE';
export const TYPE_PREBUY_SORT_LOWEST_REMAINING_QUANTITY =
  'LOWEST_REMAINING_QUANTITY';
export const TYPE_PREBUY_SORT_HIGHEST_REMAINING_QUANTITY =
  'HIGHEST_REMAINING_QUANTITY';

export const TYPE_USER_IDENTIFIER_EMAIL = 'EMAIL';
export const TYPE_USER_IDENTIFIER_PHONE_NUMBER = 'PHONE_NUMBER';

export const TYPE_USER_JOB_QUEUE_STATUS_TYPE_PENDING = 'PENDING';
export const TYPE_USER_JOB_QUEUE_STATUS_TYPE_IN_PROGRESS = 'IN_PROGRESS';
export const TYPE_USER_JOB_QUEUE_STATUS_TYPE_SUCCESS = 'SUCCESS';
export const TYPE_USER_JOB_QUEUE_STATUS_TYPE_FAILED = 'FAILED';

export const TYPE_FEEDBACK_CONTEXT_DRIVER_FEEDBACK = 'DRIVER_FEEDBACK';

export const TYPE_PAYMENT_BASE_ISSUANCE_DATE = 'ISSUANCE_DATE';
export const TYPE_PAYMENT_BASE_DEPARTURE_DATE = 'DEPARTURE_DATE';
export const TYPE_PAYMENT_BASE_NON_REFUNDABLE_DATE = 'NON_REFUNDABLE_DATE';
export const TYPE_PAYMENT_BASE_COMPLETION_DATE = 'COMPLETION_DATE';

export const TYPE_DRIVER_NOT_FOUND = 'DRIVER_NOT_FOUND';
export const TYPE_DRIVER_FILTER_TYPE_NAME_AND_PHONE =
  'DRIVER_NAME_AND_PHONE_NUMBER';

export const TYPE_DRIVER_NEW = 'NEW';
export const TYPE_DRIVER_EXISTING = 'EXISTING';

export const TYPE_SORT_ASC = 'ASC';
export const TYPE_SORT_DESC = 'DESC';

export const TYPE_EXPRESSION_EQUALS = 'EQUALS';
export const TYPE_EXPRESSION_LIKE = 'LIKE';
export const TYPE_EXPRESSION_ILIKE = 'ILIKE';
export const TYPE_EXPRESSION_IN = 'IN';
export const TYPE_EXPRESSION_OR = 'OR';
export const TYPE_EXPRESSION_AND = 'AND';
export const TYPE_EXPRESSION_IS_NULL = 'IS_NULL';
export const TYPE_EXPRESSION_NOT_NULL = 'NOT_NULL';
export const TYPE_EXPRESSION_GREATER_THAN = 'GREATER_THAN';
export const TYPE_EXPRESSION_GREATER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS';
export const TYPE_EXPRESSION_LESS_THAN = 'LESS_THAN';
export const TYPE_EXPRESSION_LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS';

export const TYPE_BOOKING_SORT_COLUMN_PICKUP_TIME = 'PICKUP_TIME';
export const TYPE_BOOKING_SORT_COLUMN_BOOKING_TIME = 'BOOKING_TIME';
export const TYPE_BOOKING_SORT_COLUMN_RESCHEDULED_PICKUP_TIME =
  'RESCHEDULED_PICKUP_TIME';

export const TYPE_PICKUP_PROCEDURE_ROUTE_GENERIC = 'GENERIC';
export const TYPE_PICKUP_PROCEDURE_ROUTE_SPECIFIC = 'SPECIFIC';

export const TYPE_PRE_TRAVEL_INFO_ROUTE_GENERIC = 'GENERIC';
export const TYPE_PRE_TRAVEL_INFO_ROUTE_SPECIFIC = 'SPECIFIC';

export const TYPE_BOOKING_POST_ISSUANCE_ACTION_REFUND = 'REFUND';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_RESCHEDULE = 'RESCHEDULE';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_REIMBURSE = 'REIMBURSE';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_REBOOK = 'REBOOK';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_BOOKING_FULFILLED =
  'BOOKING_FULFILLED';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_CONFIRM_BOOKING =
  'CONFIRM_BOOKING';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_ASSIGN_DRIVER = 'ASSIGN_DRIVER';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_CANCEL_BOOKING =
  'CANCEL_BOOKING';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_POTENTIAL_LATE =
  'POTENTIAL_LATE';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_RESOLVE_REJECT = 'REJECT';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_RESOLVE_REFUND = 'REFUND';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_RESOLVE_FILL_DRIVER_DETAILS =
  'FILL_DRIVER_DETAILS';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_RESOLVE_RESCHEDULE =
  'RESCHEDULE';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_RESOLVE_CHANGE_DRIVER =
  'CHANGE_DRIVER';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_RESOLVE_CONFIRM_BOOKING =
  'CONFIRM_BOOKING';
export const TYPE_BOOKING_POST_ISSUANCE_ACTION_RESOLVE_PAX_WAIT = 'PAX_WAIT';

export const TYPE_SKU_PRICING_TYPE_METER = 'METER';
export const TYPE_SKU_PRICING_TYPE_ZONE = 'ZONE';
export const TYPE_SKU_PRICING_TYPE_METER_AND_ZONE = 'METER_AND_ZONE';

export const TYPE_DROP_OFF = 'DROP_OFF';
export const TYPE_PICK_UP = 'PICK_UP';

export const TYPE_BY_SEARCH = 'BY_SEARCH';
export const TYPE_BY_TYPE = 'BY_TYPE';

export const TYPE_HOUR = 'HOUR';

export const TYPE_MANUAL_ASSIGNMENT = 'MANUAL_ASSIGNMENT';
export const TYPE_FIFO = 'FIFO';

export const TYPE_DISTANCE_KM = 'KM';

export const TYPE_DRIVER_QUEUE_STATUS_STANDBY = 'STANDBY';
export const TYPE_DRIVER_QUEUE_STATUS_PRE_STANDBY = 'PRE_STANDBY';
export const TYPE_DRIVER_QUEUE_STATUS_WAITING_FOR_BOOKING =
  'WAITING_FOR_BOOKING';
export const TYPE_DRIVER_QUEUE_STATUS_ASSIGNED = 'ASSIGNED';
export const TYPE_DRIVER_QUEUE_STATUS_REST = 'REST';
export const TYPE_DRIVER_QUEUE_STATUS_LEAVE_POOL = 'LEAVE_POOL';
export const TYPE_DRIVER_QUEUE_STATUS_ABSENT_OUT = 'ABSENT_OUT';
export const TYPE_DRIVER_QUEUE_STATUS_ON_THE_WAY = 'ON_THE_WAY';

export const TYPE_PAYLATER_SUPPORTED = 'PAYLATER_SUPPORTED';
export const TYPE_CREDIT_CARD_REQUIRED = 'CREDIT_CARD_REQUIRED';

export const TYPE_KEY_INFORMATION_RENTAL_REQUIREMENT = 'RENTAL_REQUIREMENT';

export const TYPE_RICH_EDITOR_BOLD = 'bold';
export const TYPE_RICH_EDITOR_ITALIC = 'italic';
export const TYPE_RICH_EDITOR_LINK = 'link';
export const TYPE_RICH_EDITOR_BULLETED_LIST = 'bulletedList';
export const TYPE_RICH_EDITOR_NUMBERED_LIST = 'numberedList';

export const TYPE_CITY_SEASON_ROUTE_ADDON_MAPPING_TYPE = 'ROUTE';

export const TYPE_TRIP_STATUS_DRIVER_NEED_REASSIGNED = 'DRIVER_NEED_REASSIGNED';
export const TYPE_TRIP_STATUS_DRIVER_ASSIGNED = 'DRIVER_ASSIGNED';
export const TYPE_TRIP_STATUS_ON_THE_WAY = 'ON_THE_WAY';
export const TYPE_TRIP_STATUS_ARRIVED_AT_PICKUP = 'ARRIVED_AT_PICKUP';
export const TYPE_TRIP_STATUS_DRIVER_CONFIRMED = 'DRIVER_CONFIRMED';
export const TYPE_TRIP_STATUS_TO_PICKUP = 'TO_PICKUP';
export const TYPE_TRIP_STATUS_COMPLETED = 'COMPLETED';

export const TYPE_ADDON_CHARGING_TYPE_HOURLY = 'HOURLY';
export const TYPE_ADDON_CHARGING_TYPE_ONE_TIME = 'ONE_TIME';

export const TYPE_DIVERLESS_RATE_SURCHARGE_OVERTIME = 'OVERTIME';
export const TYPE_DIVERLESS_RATE_SURCHARGE_PICKUP = 'PICKUP';
export const TYPE_DIVERLESS_RATE_SURCHARGE_RETURN = 'RETURN';
export const TYPE_DIVERLESS_RATE_SURCHARGE_BASIC_PRICE = 'BASIC_PRICE';

export const TYPE_DIVERLESS_RATE_PROMO_OVERTIME =
  'TOTAL_PUBLISH_PRICE_OVERTIME_PRICE';
export const TYPE_DIVERLESS_RATE_PROMO_BASIC_PRICE =
  'TOTAL_PUBLISH_PRICE_BASIC_PRICE';

export const TYPE_ROUTE_TYPE_ROUTE_NAME_OR_GEO_ID = 'ROUTE_NAME_OR_GEO_ID';
export const TYPE_ROUTE_TYPE_ROUTE_ZONAL_NAME_OR_GEO_ID =
  'ROUTE_ZONAL_NAME_OR_GEO_ID';

export const TYPE_ROUTE_TYPE_ROUTE_ZONAL_POINT_NAME_OR_GEO_ID =
  'ROUTE_ZONAL_POINT_NAME_OR_GEO_ID';

export const TYPE_ROUTE_ZONAL_TYPE_IATA = 'IATA';
export const TYPE_ROUTE_ZONAL_TYPE_LOCALITY = 'LOCALITY';
export const TYPE_ROUTE_ZONAL_TYPE_ALL = 'ALL';
export const TYPE_DRIVER_CHAT_ENABLED = 'ENABLED';

export const TYPE_DRIVER_CHAT_DISABLED = 'DISABLED';
export const TYPE_DRIVER_CHAT_NOT_DEFINED = 'NOT_DEFINED';

export const TYPE_INSURANCE_INSURANCE_BY_TRAVELOKA = 'INSURANCE_BY_TRAVELOKA';
export const TYPE_INSURANCE_INSURANCE_BY_SUPPLIER = 'INSURANCE_BY_SUPPLIER';
export const TYPE_INSURANCE_INSURANCE_BY_SUPPLIER_AND_TRAVELOKA =
  'INSURANCE_BY_SUPPLIER_AND_TRAVELOKA';
export const TYPE_INSURANCE_INSURANCE_NOT_AVAILABLE = 'INSURANCE_NOT_AVAILABLE';

export const TYPE_FARE_PROMO_TYPE_METER_BASED = 'METER_BASED';
export const TYPE_FARE_PROMO_TYPE_ZONE_BASED = 'ZONE_BASED';

export const TYPE_FARE_METER_PROMO_USAGE_CHARGING_TYPE_DISTANCE = 'DISTANCE';

export const TYPE_INFINITE = 'INFINITE';

export const TYPE_PROMO_TYPE_DRIVERLESS = 'DRIVERLESS';

export const TYPE_DRIVERLESS_RATE_PROMO_SUBJECT_TYPE_RENTAL_DURATION =
  'RENTAL_DURATION';
export const TYPE_DRIVERLESS_RATE_PROMO_CONDITION_LESS_THAN = 'LESS_THAN';
export const TYPE_DRIVERLESS_RATE_PROMO_CONDITION_LESS_THAN_EQUAL =
  'LESS_THAN_EQUAL';
export const TYPE_DRIVERLESS_RATE_PROMO_CONDITION_MORE_THAN = 'MORE_THAN';
export const TYPE_DRIVERLESS_RATE_PROMO_CONDITION_MORE_THAN_EQUAL =
  'MORE_THAN_EQUAL';
export const TYPE_DRIVERLESS_RATE_PROMO_CONDITION_LONG_TYPE_EQUAL =
  'LONG_TYPE_EQUAL';

export const TYPE_PICKUP_MANAGEMENT_USER_GROUP_TYPE_OPS = 'OPS';
export const TYPE_PICKUP_MANAGEMENT_USER_GROUP_TYPE_SUPPLIER = 'SUPPLIER';
export const TYPE_PICKUP_MANAGEMENT_USER_GROUP_TYPE_OTHER = 'OTHER';

export const TYPE_PICKUP_MANAGEMENT_NOTIFICATION_TYPE_REFUND = 'REFUND';
export const TYPE_PICKUP_MANAGEMENT_NOTIFICATION_TYPE_RESCHEDULE = 'RESCHEDULE';
export const TYPE_PICKUP_MANAGEMENT_NOTIFICATION_TYPE_POTENTIAL_LATE =
  'POTENTIAL_LATE';
export const TYPE_PICKUP_MANAGEMENT_NOTIFICATION_TYPE_CONFIRM_BOOKING =
  'CONFIRM_BOOKING';
export const TYPE_PICKUP_MANAGEMENT_NOTIFICATION_TYPE_ASSIGN_DRIVER =
  'ASSIGN_DRIVER';

export const TYPE_HEALTH_MONITORING_PERIOD_TIME_BEFORE_DEPARTURE =
  'BEFORE_DEPARTURE';
export const TYPE_HEALTH_MONITORING_PERIOD_TIME_AFTER_DEPARTURE =
  'AFTER_DEPARTURE';

export const TYPE_OPERATIONAL_HOUR_TYPE_DELIVER = 'DELIVER';
export const TYPE_OPERATIONAL_HOUR_TYPE_RETURN = 'RETURN';
export const TYPE_OPERATIONAL_HOUR_TYPE_PICKUP = 'PICKUP';
export const TYPE_OPERATIONAL_HOUR_TYPE_DROPOFF = 'DROPOFF';
export const TYPE_OPERATIONAL_HOUR_TYPE_BOTH = 'BOTH';
export const TYPE_OPERATIONAL_HOUR_TYPE_REGULAR = 'REGULAR';
export const TYPE_OPERATIONAL_HOUR_TYPE_SEASONAL = 'SEASONAL';
export const TYPE_OPERATIONAL_HOUR_TYPE_POOL_OPERATIONAL_HOUR =
  'POOL_OPERATIONAL_HOUR';
export const TYPE_OPERATIONAL_HOUR_TYPE_OUTSIDE_POOL_OPERATIONAL_HOUR =
  'OUTSIDE_POOL_OPERATIONAL_HOUR';
export const TYPE_OPERATIONAL_HOUR_TYPE_INTERCITY_OPERATIONAL_HOUR =
  'INTERCITY_OPERATIONAL_HOUR';

export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_WAITING_FOR_ACKNOWLEDGE =
  'WAITING_FOR_ACKNOWLEDGE';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_ACKNOWLEDGED =
  'ACKNOWLEDGED';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_DRIVER_ASSIGNED =
  'DRIVER_ASSIGNED';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_DRIVER_CONFIRMED =
  'DRIVER_CONFIRMED';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_TRIP_COMPLETED =
  'TRIP_COMPLETED';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REFUNDED = 'REFUNDED';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_REFUND =
  'REQUEST_REFUND';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_RESCHEDULED =
  'RESCHEDULED';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_RESCHEDULE =
  'REQUEST_RESCHEDULE';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_POTENTIAL_LATE =
  'POTENTIAL_LATE';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_PAX_AGREE_TO_WAIT =
  'PAX_AGREE_TO_WAIT';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_ACKNOWLEDGE =
  'REQUEST_ACKNOWLEDGE';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_ASSIGN_DRIVER =
  'REQUEST_ASSIGN_DRIVER';
export const TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REJECTED =
  'REJECTED';

export const TYPE_NOTIFICATION_BUTTON_STATE_IDLE = 'IDLE';
export const TYPE_NOTIFICATION_BUTTON_STATE_ACTIVE = 'ACTIVE';

export const TYPE_NOTIFICATION_STATE_NEW = 'NEW';
export const TYPE_NOTIFICATION_STATE_RESOLVED = 'RESOLVED';
export const TYPE_NOTIFICATION_STATE_URGENT = 'URGENT';

export const TYPE_NOTIFICATION_TYPE_CONFIRM_BOOKING = 'CONFIRM_BOOKING';
export const TYPE_NOTIFICATION_TYPE_ASSIGN_DRIVER = 'ASSIGN_DRIVER';
export const TYPE_NOTIFICATION_TYPE_REQUEST_REFUND = 'REQUEST_REFUND';
export const TYPE_NOTIFICATION_TYPE_REQUEST_RESCHEDULE = 'REQUEST_RESCHEDULE';
export const TYPE_NOTIFICATION_TYPE_POTENTIAL_LATE = 'POTENTIAL_LATE';

export const TYPE_LEAD_TIME_TYPE_REGULAR = 'REGULAR';
export const TYPE_LEAD_TIME_TYPE_SEASONAL = 'SEASONAL';
export const TYPE_LEAD_TIME_TYPE_INTERCITY = 'INTERCITY';

export const TYPE_BOOKING_MANAGEMENT_DETAIL_PICKUP_SURCHARGE = 'PICKUP';
export const TYPE_BOOKING_MANAGEMENT_DETAIL_RETURN_SURCHARGE = 'RETURN';

export const TYPE_ADDON_TARGET_TYPE_BID = 'BID';
export const TYPE_ADDON_TARGET_TYPE_QUANTITY = 'QUANTITY';

export const TYPE_RANGE_VALUE_UNIT_MINUTES = 'MINUTES';

export const TYPE_DRIVER_COMMISSION_VIEW_TYPE_RENTAL_PRICE = 'RENTAL_PRICE';
export const TYPE_DRIVER_COMMISSION_VIEW_TYPE_COMMISSION = 'COMMISSION';
export const TYPE_DRIVER_COMMISSION_VIEW_TYPE_NONE = 'NONE';

export const TYPE_CUTOFF_TYPE_TWENTY_FOUR_HOURS = 'TWENTY_FOUR_HOURS';
export const TYPE_CUTOFF_TYPE_END_OF_DAY = 'END_OF_DAY';
export const TYPE_CUTOFF_TYPE_NOT_APPLICABLE = 'NOT_APPLICABLE';

export const TYPE_PAYMENT_ELIGIBLE_TYPE_DEFAULT = 'DEFAULT';
export const TYPE_PAYMENT_ELIGIBLE_TYPE_BU_CONFIRMATION = 'BU_CONFIRMATION';